export default {
  props: {
    listKey: String,
    name: String,
    config: Object,
    setupItemsCallback: Function,
  },
  data: () => ({
    enabled: false,
    filterValue: null,
  }),
  created() {
    this.setupItemsCallback({
      name: this.name,
      callback: this.onStartFilterEvent,
    })
  },
  methods: {
    async onEnabledChange(value) {
      await this.$nextTick()
      this.enabled = value
    },
    onStartFilterEvent(data) {
      if(!this.enabled) return // 未啟動此欄位篩選
      if(this.isValueEmpty) return // 欄位的值為空
      data[this.name] = this.filterValue
    },
    async setupFilterValue() {
      if(!this.storeFilterValueValid) return true
      this.filterValue = window.eagleLodash.cloneDeep(this.storeFilterValue)
      await this.$nextTick()
      this.enabled = !this.isValueEmpty
    },
    triggerFilter() {
      this.$emit('triggerFilter')
    },
  },
  computed: {
    storeFilterValueValid() {
      return true
    },
    isValueEmpty() {
      if(this.filterValue == null) return true
      if(this.filterValue == undefined) return true
      return window.eagleLodash.isEmpty(this.filterValue)
    },
    listFilter() {
      return this.$store.getters[`list/${this.listKey}/filter`]
    },
    listMeta() {
      return this.$store.getters[`list/${this.listKey}/meta`]
    },
    storeFilterValue() {
      if(!this.listFilter) return null
      const value = this.listFilter[this.name]
      if(value == null || value == undefined) return null
      if(value == 'true' || value === true) return true
      if(value == 'false' || value === false) return false
      if(value == '1' || value === 1) return 1
      if(value == '0' || value === 0) return 0
      return value
    },
  },
  watch: {
    storeFilterValue: {
      immediate: true,
      deep: true,
      handler() {
        this.setupFilterValue()
      },
    }
  },
}
